@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "AVENIR";
	src: url("../public/fonts/AvenirLTStd-Medium.otf");
}

.logo-miri {
	background-image: url('/public/images/logo_miri.png');
}

.logo-miri-pro {
	background-image: url('/public/images/logo_miri_pro.png');
}

.logo-pinguin {
	background-image: url('/public/images/logo_pinguin.png');
}

/* Font Predefinito */
@import url('https://fonts.googleapis.com/css2?family=Avenir:wght@400;700&display=swap');

/* Font Alternativo per il tema Blu */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

:root {
  --font-primary: 'Avenir', sans-serif;
  --color-primary: 239, 125, 0; /* #EF7D00 */
  --color-primary-light: 255, 227, 166; /* #FFE3A6 */
  --color-g-primary-light: 249, 178, 51; /* #F9B233 */
  --color-g-primary-dark: 238, 114, 3; /* #EE7203 */
  --color-gray: 105, 105, 105; /* #696969 */
  --color-gray2: 135, 135, 135; /* #878787 */
  --color-lightgray: 211, 211, 211; /* #D3D3D3 */
  --color-lightblue: 248, 252, 253; /* #F8FCFD */
  --color-white: 255, 255, 255;
}

/* Tema Blu */
[data-theme="miripro"] {
  --font-primary: 'Montserrat', sans-serif;
  --color-primary: 87, 144, 171; /* #5890AB */
  --color-primary-light: 215, 236, 244; /* #D7ECF4 */
  --color-g-primary-light: 153, 190, 219; /* #99BEDB */
  --color-g-primary-dark: 78, 128, 152; /* #4E8098 */
  --color-gray: 105, 105, 105; /* #696969 */
  --color-gray2: 135, 135, 135; /* #878787 */
  --color-lightgray: 211, 211, 211; /* #D3D3D3 */
  --color-lightblue: 248, 252, 253; /* #F8FCFD */
  --color-white: 255, 255, 255;
}